import classnames from 'classnames';
import { Combobox } from '@headlessui/react';
import { X, CaretDown } from '@phosphor-icons/react';
import { ChangeEvent } from 'react';
import { AutocompleteProps } from '../types';
import { Input } from './Input';
import { Label } from './Label';
import { SelectSingleOption } from './SelectSingle';
import { Spinner } from './Spinner';

export interface AutocompleteSingleOption extends SelectSingleOption {
  [extra: string]: any;
}

export interface AutocompleteSingleProps<T extends AutocompleteSingleOption>
  extends AutocompleteProps {
  options: AutocompleteSingleOption[];
  selected?: T | null;
  setSelected: (item: T | null) => void;
}

/**
 * @name AutocompleteSingle
 * @description A Combobox component allowing text input with autocomplete.
 * Wraps headlessui's Combobox component
 */
export const AutocompleteSingle = <T extends AutocompleteSingleOption>(
  props: AutocompleteSingleProps<T>,
) => {
  const {
    className,
    disabled = false,
    id,
    label,
    loading,
    maxDropdownWidth,
    onChange,
    options = [],
    placeholder,
    selected,
    setSelected,
    showErrors,
    value,
  } = props;

  // Hooks

  // Setup
  const onClickClear = () => {
    setSelected(null);
    onChange?.({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
  };

  // Handlers

  // Markup
  const inputIcon = value ? (
    <button aria-label="Clear" disabled={disabled} onClick={onClickClear}>
      <X
        className={classnames([
          'content-secondary h-5 w-5',
          disabled && 'content-disabled',
        ])}
      />
    </button>
  ) : (
    <Combobox.Button as="span">
      <CaretDown className="content-secondary h-5 w-5" />
    </Combobox.Button>
  );

  // 🔌 Short Circuits

  return (
    <div className={className}>
      {!!label && (
        <Label
          className="pb-1"
          id={id}
          onClick={(event) => event.preventDefault()}
        >
          {label}
        </Label>
      )}

      <Combobox
        disabled={disabled}
        multiple={false}
        onChange={setSelected}
        value={selected}
      >
        <div className="relative flex">
          <Combobox.Button className="w-full">
            <Combobox.Input
              as={Input}
              className="truncate"
              classNameInput={classnames(
                value !== '' && 'pr-7',
                showErrors && 'content-danger ring-red/80 focus:ring-red pr-10',
                disabled && 'content-disabled',
              )}
              id={id}
              onChange={onChange}
              placeholder={placeholder}
              style={{ textOverflow: 'ellipsis' }}
              type="search"
              value={value}
            />
          </Combobox.Button>

          <span
            className={classnames([
              'absolute inset-y-0 right-0 flex items-center pr-2',
              disabled && 'content-disabled',
              !disabled && 'cursor-pointer',
            ])}
          >
            {loading ? (
              <Spinner className="h-full w-full pb-2.5 pt-2.5" />
            ) : (
              inputIcon
            )}
          </span>
        </div>

        <div className="relative">
          <Combobox.Options
            className="surface-primary absolute z-10 mt-1 max-h-60 min-w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            style={{ maxWidth: maxDropdownWidth ?? '200%' }}
          >
            {options.length === 0 ? (
              <div className="content-primary relative cursor-default select-none px-4 py-2">
                Nothing found.
              </div>
            ) : (
              options.map((option) => (
                <Combobox.Option
                  className={({ active, selected }) =>
                    classnames(
                      active || selected
                        ? 'surface-accent text-white'
                        : 'content-primary',
                      'relative z-10 flex cursor-pointer select-none items-center gap-2 truncate px-3 py-2',
                    )
                  }
                  key={option.value}
                  value={option}
                >
                  {option.icon}
                  {option.name}
                  <span className="content-disabled text-xs">
                    {option.extra}
                  </span>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
};
