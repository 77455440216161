import classnames from 'classnames';
import * as React from 'react';
import { Combobox } from '@headlessui/react';
import { X, CaretDown } from '@phosphor-icons/react';
import { ChangeEvent } from 'react';
import { AutocompleteProps } from '../types';
import { Spinner } from './Spinner';
import { Input } from './Input';
import { Label } from './Label';
import { TagItem } from './TagItem';
import { TagLogicalOperator } from './TagLogicalOperator';
import { SelectSingleOption } from './SelectSingle';

export interface AutocompleteMultipleOption extends SelectSingleOption {
  [extra: string]: any;
}

export interface AutocompleteMultipleProps<T extends AutocompleteMultipleOption>
  extends AutocompleteProps {
  options: T[];
  selected?: T[];
  setSelected: (selectedItems: T[]) => void;
  showSelected?: boolean;
}

/**
 * @name AutocompleteMultiple
 * @description A Combobox component allowing text input with AutocompleteMultiple.
 * Wraps headlessui's Combobox component
 */
export const AutocompleteMultiple = <T extends AutocompleteMultipleOption>(
  props: AutocompleteMultipleProps<T>,
) => {
  const {
    className,
    disabled = false,
    id,
    loading,
    label,
    maxDropdownWidth,
    onChange,
    options = [],
    placeholder,
    selected = [],
    setSelected,
    showErrors,
    showSelected = true,
    value,
  } = props;

  // Hooks

  // Setup
  const onClickClear = () => {
    setSelected([]);
    onChange?.({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
  };

  // Handlers
  const removeItem = (item: T) => {
    const newSelectedOptions = selected.filter(
      (selectedOption) => selectedOption.value !== item.value,
    );
    setSelected(newSelectedOptions);
  };

  // Markup
  const inputIcon =
    value || selected.length ? (
      <button aria-label="Clear" disabled={disabled} onClick={onClickClear}>
        <X
          className={classnames([
            'content-secondary h-5 w-5',
            disabled && 'content-disabled',
          ])}
          data-testid="clear-icon"
        />
      </button>
    ) : (
      <Combobox.Button as="span">
        <CaretDown className="content-secondary h-5 w-5" />
      </Combobox.Button>
    );

  // 🔌 Short Circuits

  return (
    <div className={className}>
      {!!label && (
        <Label
          className="pb-1"
          id={id}
          onClick={(event) => event.preventDefault()}
        >
          {label}
        </Label>
      )}

      <Combobox
        disabled={disabled}
        multiple={true}
        onChange={setSelected}
        value={selected}
      >
        {showSelected && selected.length > 0 && (
          <span className="background-main ring-gray-7 mb-2 flex flex-wrap rounded-md p-2 ring-1 ring-inset">
            {selected.map((item, index) => (
              <React.Fragment key={item.value}>
                <TagItem
                  id={item.value}
                  key={item.value}
                  label={item.name}
                  removeItem={() => removeItem(item)}
                />
                {index !== selected.length - 1 && <TagLogicalOperator />}
              </React.Fragment>
            ))}
          </span>
        )}

        <div className="relative flex">
          <Combobox.Button className="w-full">
            <Combobox.Input
              as={Input}
              className="truncate"
              classNameInput={classnames(
                value !== '' && 'pr-7',
                showErrors && 'content-danger ring-red/80 focus:ring-red pr-10',
                disabled && 'content-disabled',
              )}
              id={id}
              onChange={onChange}
              placeholder={placeholder}
              style={{ textOverflow: 'ellipsis' }}
              type="search"
              value={value}
            />
          </Combobox.Button>

          <span
            className={classnames([
              'absolute inset-y-0 right-0 flex items-center pr-2',
              disabled && 'content-disabled',
              !disabled && 'cursor-pointer',
            ])}
          >
            {loading ? (
              <Spinner className="h-full w-full pb-2.5 pt-2.5" />
            ) : (
              inputIcon
            )}
          </span>
        </div>

        <div className="relative">
          <Combobox.Options
            className="background-main absolute z-10 mt-1 max-h-60 min-w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            style={{ maxWidth: maxDropdownWidth ?? '200%' }}
          >
            {options.length === 0 ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                Nothing found.
              </div>
            ) : (
              options.map((option) => (
                <Combobox.Option
                  className={({ active, selected }) =>
                    classnames(
                      active || selected
                        ? 'surface-accent text-white'
                        : 'content-primary',
                      'relative z-10 flex cursor-pointer select-none items-center gap-2 truncate px-3 py-2',
                    )
                  }
                  key={option.value}
                  value={option}
                >
                  {option.icon}
                  {option.name}
                  <span className="content-disabled text-xs">
                    {option.extra}
                  </span>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
};
